import request, { formatGetOptions, formatPutOptions, formatPostOptions as postOptions } from '../utils/request';
import { BASE_URL_V2 } from './api-service/Url_Constants';

export const listProviderAccounts = async () => {
    const options = formatGetOptions();

    const data = await request(`${BASE_URL_V2}/accounts/provider/accounts/list`, options);

    if (Array.isArray(data?.payload)) {
        return data.payload.map((account) => ({
            providerAccountId: account.providerAccountId,
            providerAccountName: account.providerAccountName,
            providerName: account.provider,
            providerBrandId: account.providerBrandId,
            providerBrandName: account.providerBrandName,
            providerAccountOrgId: account.orgId,
            providerAccountOrgName: account.orgName,
            myadsBrandId: account.brandId,
            myadsBrandName: account.brandName,
            state: account.state,
            id: account.id,
            createdAt: account.createdAt,
            updatedAt: account.updatedAt,
        }));
    }

    return [];
};

export const createKoddiProvider = async (values) => {
    const { orgId } = values.payload;
    const myadsBrandId = values.payload.myadsBrandId[0].value;

    const payload = {
        orgId,
        myadsBrandId,
        catalogBrandIds: values.payload.productCatalogBrands.map((brand) => brand.value),
    };

    const options = postOptions(payload);

    const data = await request(`${BASE_URL_V2}/accounts/koddi/advertisers/create`, options);

    return formatProviderResponse(data, orgId, myadsBrandId);
};

export const updateKoddiProviderAccount = async (values) => {
    const { orgId, providerAccountId } = values.payload;
    const myadsBrandId = values.payload.myadsBrandId[0].value;

    const payload = {
        orgId,
        myadsBrandId,
        providerAccountId,
        catalogBrandIds: values.payload.productCatalogBrands.map((brand) => brand.value),
    };

    const options = formatPutOptions(payload);

    const data = await request(`${BASE_URL_V2}/accounts/koddi/advertisers/update`, options);

    return formatProviderResponse(data, orgId, myadsBrandId);
};

export const getProviders = async (values) => {
    const { orgId } = values.payload;
    const { myadsBrandId } = values.payload;

    const options = formatGetOptions();

    const data = await request(`${BASE_URL_V2}/accounts/provider/orgs/${orgId}/brands/${myadsBrandId}`, options);

    let providerWithCatBrands;

    // eslint-disable-next-line no-restricted-syntax
    for (const provider of data?.payload.providers) {
        if (provider.provider === 'Koddi') {
            const { providerId, providerAccountId, providerAccountName } = provider;
            const { catalogBrands } = data?.payload;

            providerWithCatBrands = {
                providerId,
                providerAccountId,
                providerAccountName,
                catalogBrands,
            };

            return { ...providerWithCatBrands, orgId, myadsBrandId };
        }
    }
    return {};
};

const formatProviderResponse = (data, orgId, myadsBrandId) => ({
    providerId: data?.body?.provider?.id,
    providerAccountId: data?.body?.provider?.provider_account_id,
    providerAccountName: data?.body?.provider?.provider_account_name,
    catalogBrands: data?.body?.catalogBrandIds,
    orgId,
    myadsBrandId,
});
