import request, { formatGetOptions as getOptions, formatPostOptions, formatPutOptions } from '../utils/request';
import { responseMapping } from './api-helpers/responseMapping';
import { BASE_URL_V2 } from './api-service/Url_Constants';
import {
    addOrgResponseMap,
    formatAccountResponse,
    organizationFieldsMap,
    orgsAndBrandsFieldsMap,
    addBrandSchema,
    updateBrandSchema,
} from './api-service/maps';

export const fetchOrganizations = async () => {
    const options = getOptions();
    const response = await request(`${BASE_URL_V2}/accounts/ui/listOrgs`, options);
    return response.payload.map((org) => organizationFieldsMap(org));
};

export const fetchAdminOrgs = async () => {
    const options = getOptions();

    const response = await request(`${BASE_URL_V2}/accounts/ui/listOrgsAndBrands`, options);

    return response.payload.map((org) => orgsAndBrandsFieldsMap(org));
};

export const getOrgTypes = async () => {
    const options = getOptions();
    const response = await request(`${BASE_URL_V2}/accounts/org/listOrgTypes`, options);
    return response.payload.map((type) => ({
        name: type,
        value: type,
    }));
};

export const addBrand = async (values) => {
    const options = formatPostOptions(values.payload);
    const response = await request(`${BASE_URL_V2}/accounts/brand/create`, options);
    return responseMapping(addBrandSchema, response.payload);
};

export const updateBrand = async (brandId, brandName) => {
    const requestData = { name: brandName, brand_id: brandId, state: 'active' };
    const options = formatPutOptions(requestData);
    const { payload } = await request(`${BASE_URL_V2}/accounts/brand/update`, options);
    return responseMapping(updateBrandSchema, payload);
};

export const addOrg = async (values) => {
    const options = formatPostOptions({ name: values.name, org_type: values.type, state: 'active' });
    const response = await request(`${BASE_URL_V2}/accounts/org/create`, options);
    return addOrgResponseMap(response.payload);
};

export const updateOrg = async (values) => {
    const options = formatPutOptions({
        name: values.name,
        org_type: values.type,
        org_id: values.orgId,
        state: 'active',
    });

    const response = await request(`${BASE_URL_V2}/accounts/org/update`, options);
    return response;
};

export const moveAccount = async (values) => {
    const options = formatPutOptions({ ...values.payload });
    const response = await request(`${BASE_URL_V2}/accounts/provider/accounts/update`, options);

    return {
        providerAccountId: response.payload.providerAccountId,
        providerAccountName: response.payload.providerAccountName,
        providerAccountType: response.payload.providerAccountType,
        providerName: response.payload.provider,
        providerBrandId: response.payload.providerBrandId,
        providerBrandName: response.payload.providerBrandName,
        providerAccountOrgId: response.payload.orgId,
        providerAccountOrgName: response.payload.orgName,
        myadsBrandId: response.payload.brandId,
        myadsBrandName: response.payload.brandName,
        state: response.payload.state,
        id: response.payload.id,
        createdAt: response.payload.createdAt,
        createdBy: response.payload.createdBy,
        updatedAt: response.payload.updatedAt,
        updatedBy: response.payload.updatedBy,
    };
};

export const updateAccount = async (values) => {
    const options = formatPutOptions({ ...values });
    const response = await request(`${BASE_URL_V2}/accounts/provider/accounts/update`, options);

    return formatAccountResponse(response.payload);
};

export const addAccount = async (values) => {
    const options = formatPostOptions({
        ...values,
    });
    const response = await request(`${BASE_URL_V2}/accounts/provider/accounts/create`, options);

    return formatAccountResponse(response.payload);
};
