/**
 *
 * SummaryDashboardPage
 *
 */

import React, { memo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Vehicle } from 'vehicles';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from 'redux-injectors';
import SummaryTable from 'components/SummaryTable';
import { makeStyles } from '@material-ui/core/styles';
import { useRequest } from 'shared/hooks/use-request';
import { fetchSummaryOverview, fetchSummaryOverviewChart } from 'api/api-client';
import { makeSelectCampaignFilters } from 'containers/App/selectors';
import { columns, totalColumns } from './columnDefs';
import { selectBrands } from '../OrganizationPage/selectors';
import { getFilters, addFilter, updateFilter } from '../HomePage/actions';
import { AppContext } from '../../shared/contexts/app-context';
import { selectDateFilter, selectCurrentFilter, selectCurrentFilterName } from './selectors';
import TitleBar from '../../components/TitleBar';
import SummaryChartWrapper from '../../components/SummaryDashboardChart/SummaryChartWrapper';
import saga from '../HomePage/saga';
import { VISUALIZATION_OPTIONS_L1, DEFAULT_FILTER_NAME } from './constants';

const useStyles = makeStyles((theme) => ({
    chartWrapper: {
        fontStyle: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        marginBottom: 40,
    },
    summaryTable: {
        overflowY: 'auto',
    },
}));

export function SummaryDashboardPage({
    fetchFilters,
    filterMetadata,
    summaryFilter,
    summaryFilterName,
    onFilterAdd,
    onFilterSubmit,
    dateFilter,
    brands,
}) {
    const { authedUser } = useContext(AppContext);
    const classes = useStyles();
    const history = useHistory();

    useInjectSaga({ key: 'summaryDashboardPage', saga });

    const onSummaryFilterChange = (filter) => {
        if (!summaryFilterName) {
            onFilterAdd(authedUser.email, DEFAULT_FILTER_NAME, filter);
        } else {
            onFilterSubmit(authedUser.email, DEFAULT_FILTER_NAME, filter);
        }
    };

    const [fetchSummary, summaryOverviewRequest] = useRequest(fetchSummaryOverview);
    const [fetchChart, summaryChartRequest] = useRequest(fetchSummaryOverviewChart);

    useEffect(() => {
        if (authedUser?.email) {
            fetchFilters(authedUser.email);
        }
    }, [fetchFilters, authedUser.email]);

    const { startDate, endDate } = dateFilter;

    useEffect(() => {
        fetchSummary(startDate, endDate, summaryFilter);
        fetchChart(startDate, endDate, summaryFilter);
    }, [summaryFilter, fetchSummary, fetchChart, startDate, endDate]);

    const navTo = (dataItem) => {
        const newVehicle = Vehicle.getFromCampaignType(dataItem.campaignType);
        history.push(`/campaigns/summary/${newVehicle.getVehicleKey()}`);
    };

    return (
        <div>
            <TitleBar
                title="Summary Dashboard"
                onSummaryFilterChange={onSummaryFilterChange}
                currentSummaryFilter={summaryFilter}
                filterMetadata={filterMetadata}
                brandData={brands}
                dateFilter={dateFilter}
            />
            <div className={classes.chartWrapper}>
                <SummaryChartWrapper
                    chartData={summaryChartRequest.data}
                    cardData={summaryOverviewRequest.data?.items}
                    visualizationOptions={VISUALIZATION_OPTIONS_L1}
                    isLoading={summaryChartRequest.isInit || summaryChartRequest.isLoading}
                    cardsLoading={summaryOverviewRequest.isInit || summaryOverviewRequest.isLoading}
                    isStyleL1
                />
            </div>
            <SummaryTable
                loading={summaryOverviewRequest.isInit || summaryOverviewRequest.isLoading}
                items={summaryOverviewRequest.data?.items}
                totalItems={summaryOverviewRequest.data?.itemTotals}
                columns={columns}
                totalColumns={totalColumns}
                onClick={navTo}
                noRecordsText="No Campaigns Available"
                summaryDashboardL1
            />
        </div>
    );
}

SummaryDashboardPage.propTypes = {
    fetchFilters: PropTypes.func,
    dateFilter: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }),
    filterMetadata: PropTypes.shape({
        brand_name: PropTypes.object,
        category_groups: PropTypes.object,
    }),
    summaryFilter: PropTypes.object,
    summaryFilterName: PropTypes.string,
    onFilterAdd: PropTypes.func,
    onFilterSubmit: PropTypes.func,
    brands: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    dateFilter: selectDateFilter,
    summaryFilterName: selectCurrentFilterName,
    summaryFilter: selectCurrentFilter,
    brands: selectBrands,
    filterMetadata: makeSelectCampaignFilters(),
});

export function mapDispatchToProps(dispatch) {
    return {
        fetchFilters: (userId) => dispatch(getFilters(userId)),
        onFilterAdd: (userId, filterName, filter) => dispatch(addFilter(userId, filterName, filter)),
        onFilterSubmit: (userId, filterName, filter) => dispatch(updateFilter(userId, filterName, filter)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SummaryDashboardPage);
